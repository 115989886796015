import React, { useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "@material-ui/core";

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null)

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements) {
            
            return;
        }

        const {errorMsg} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: "https://www.youtube.com"
            },
        });

        if (errorMsg) {
            setErrorMessage(errorMsg)
        } else {

        }



    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement/>
            <Button onClick={handleSubmit} variant="contained" style={{marginTop: 30, width: "100%", height: 44, backgroundColor: "#30313d", color: "white", border: "1px solid #424353", boxShadow: "0px 2px 4px rgb(0 0 0 / 50%), 0px 1px 6px rgb(0 0 0 / 25%)", fontFamily: "Segoe UI", textTransform: "none", fontSize: "1rem", fontSmooth: "always"}} disabled={!stripe}>Submit</Button>
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    )
}

export default CheckoutForm