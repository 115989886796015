import React from "react";
import Meta from "./../components/Meta";
import LegalSection from "./../components/LegalSection";
import { useRouter } from "./../util/router";
import Navbar from "../components/Navbar";

function LegalPage(props) {
  const router = useRouter();

  return (
    <>

      <Navbar
          color="default"
          logo="https://uploads.divjoy.com/logo.svg"
          logoInverted="https://uploads.divjoy.com/logo-white.svg"
      />

      <Meta title="Legal" />
      <LegalSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </>
  );
}

export default LegalPage;
