import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";
import Navbar from "./../components/Navbar";
import "../components/general.css"
import logoLight from "../components/logo_svg_light_final.svg"
import logoDark from "../components/logo_svg_dark_final.svg"
import { Typography } from "@material-ui/core";
import { AspectRatio } from "@material-ui/icons";
import { useDarkMode } from "../util/theme";
import lettersGif from "../components/lettersgif.gif";
import "../components/generalscss.scss";
import Footer from "../components/Footer";
import "../components/general.css"
import "../components/monospacefontcss.css"

function IndexPage(props) {
  return (
    <>
      <div style={{overflowX: "clip"}}>
          <Navbar
            color="default"
            logo={logoLight}
            logoInverted={logoDark}
          />

        <Meta />
        <HeroSection
          bgColor="black"
          size="large"
          bgImage={lettersGif}
          bgImageOpacity={1}
          title={<div style={{fontSize: 64, color: "#d9d9d9", fontFamily: "fontification"}}>incog.<b style={{color: "#59A7FD"}}>ai</b></div>}
          subtitle={<div><div style={{color: "white", fontFamily: "fontification"}}>Write human - with AI.</div><h1 style={{fontSize: 16, marginBottom: 0, color: "orange", fontFamily: "fontification"}}>(beta)</h1></div>}
          buttonText="Try Free"
          buttonColor="default"
          buttonPath="/pricing"
        />
        
        <div style={{position: "absolute", width: "100%"}}>
              <div className="gridBody">
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                <div className="cell"></div>
                
                <div className="content">
                  <FeaturesSection
                    bgColor="default"
                    size="medium"
                    bgImage=""
                    bgImageOpacity={1}
                    title="Features"
                    subtitle="*Our advanced human-like AI writing service is being tested in beta, be on the lookout for updates and new features*"
                  />
                  <div>
                    <Typography style={{fontFamily: "fontification", textAlign: "center", paddingBottom: 32, fontSize: 32}}>Not convinced?</Typography>
                  	<iframe style={{marginLeft: "50%", transform: "translate(-50%, 0px)", aspectRatio: "1.777282850779510022271714922049", borderRadius: 12, marginBottom: 20, boxShadow: useDarkMode().value ? "0px 0px 15px gray" : "0px 0px 15px black", marginBottom: 35, maxWidth: "700px", width: "max(50%, min(96%, 500px))", position: "absolute"}} src="https://www.youtube.com/embed/3LH1cDTCByI" title="RG" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
					<div style={{aspectRatio: "1.777282850779510022271714922049", width: "max(50%, min(100%, 400px))", marginTop: 80}}></div>

				  </div>
                  <NewsletterSection
                    bgColor="light"
                    size="medium"
                    bgImage=""
                    bgImageOpacity={1}
                    title="Stay in the know"
                    subtitle="Be the first to know about new features and upcoming site maintenance."
                    buttonText="Subscribe"
                    buttonColor="primary"
                    inputPlaceholder="Enter your email"
                    subscribedMessage="You are now subscribed!"
                  />
                  <CtaSection
                    bgColor="primary"
                    size="medium"
                    bgImage=""
                    bgImageOpacity={1}
                    title="Ready to get started?"
                    subtitle=""
                    buttonText="Try Free"
                    buttonColor="default"
                    buttonPath="/pricing"
                  />
                  <Footer
                  bgColor="default"
                  size="medium"
                  bgImage=""
                  bgImageOpacity={1}
                  description="Write human - with AI."
                  copyright={`© ${new Date().getFullYear()} Incog Technologies`}
                  logo="https://uploads.divjoy.com/logo.svg"
                  logoInverted="https://uploads.divjoy.com/logo-white.svg"
                  sticky={true}
                  />
                </div>

                

              </div>
            </div> 
          </div>      
    </>
  );
}

export default IndexPage;
