import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";
import Navbar from "./../components/Navbar";
import { Typography } from "@material-ui/core";
import { useDarkMode } from "../util/theme";
import "../components/monospacefontcss.css"

function AboutPage(props) {
  return (
    <>
      <Navbar
          color="default"
          logo="https://uploads.divjoy.com/logo.svg"
          logoInverted="https://uploads.divjoy.com/logo-white.svg"
      />

      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bgColor="default"
        size="large"
        bgImage=""
        bgImageOpacity={1}
        title="incog.ai"
        subtitle="We believe that AI is the future, we hope to improve the integration of AI into workflows for people all across the world. Our AI writer revolutionizes the AI writing industry with advanced techniques that humanize AI text generation. Our success in this matter is evident in the tendency of our outputs to remain undetected by even the best AI writing detection systems."
      />
      <div>
        <Typography style={{fontFamily: "fontification", textAlign: "center", paddingBottom: 32, fontSize: 32}}>Our product:</Typography>
        <iframe style={{marginLeft: "50%", transform: "translate(-50%, 0px)", aspectRatio: "1.777282850779510022271714922049", borderRadius: 12, marginBottom: 20, boxShadow: useDarkMode().value ? "0px 0px 15px gray" : "0px 0px 15px black", marginBottom: 35, maxWidth: "700px", width: "max(50%, min(96%, 500px))", position: "absolute"}} src="https://www.youtube.com/embed/3LH1cDTCByI" title="RG" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
		<div style={{aspectRatio: "1.777282850779510022271714922049", width: "max(50%, min(100%, 400px))", marginTop: 70}}></div>

	  </div>
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Try Free"
        buttonColor="default"
        buttonPath="/pricing"
      />
    </>
  );
}

export default AboutPage;
