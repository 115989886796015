import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
import Navbar from "../components/Navbar";

function PricingPage(props) {
  return (
    <>

      <Navbar
          color="default"
          logo="https://uploads.divjoy.com/logo.svg"
          logoInverted="https://uploads.divjoy.com/logo-white.svg"
      />

      <Meta title="Pricing" />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle=""
      />
    </>
  );
}

export default PricingPage;
