import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import sneakSvg from "./sneak.svg"
import lightbulbSvg from "./lightbulb.svg"
import relaxSvg from "./relax.svg"
import agreementSvg from "./agreement.svg"
import { wrap } from "analytics/lib/analytics.cjs";
import useLocalStorageState from "use-local-storage-state";
import "./monospacefontcss.css"
import { useRef } from "react";
import { useDarkMode } from "../util/theme";
import "./general.css"
import "./generalscss.scss"

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    maxWidth: 300,
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
  },
}));


function FeaturesSection(props) {

  const classes = useStyles();
  const [AntiDetectionText, setAntiDetectionText] = useState("")

  const ref1 = React.useRef()
  const ref2 = React.useRef()
  const ref3 = React.useRef()
  const ref4 = React.useRef()
  const ref5 = React.useRef()
  const ref6 = React.useRef()
  const ref7 = React.useRef()
  const ref8 = React.useRef()

  const charArr = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")"];
  const corruptionColor = "#59A7FD"

  const print = async (loop, obf, deobf, temp, interval) => {
    if (loop.current != temp.current.length) {
      let obfArr = obf.current.split('')

      for (let i = 0; i < obfArr.length; i++) {
        if (obfArr[i] != " ") {
          obfArr[i] = charArr[Math.floor(Math.random() * charArr.length)]
        }
      }

      obfArr.splice(0, 1)
      obf.current = obfArr.join('')

      deobf.current = deobf.current + temp.current.split('')[loop.current]

    

      loop.current = loop.current += 1
      setAntiDetectionText(loop.current)
    } else {
      clearInterval(interval.current)
    }

  }

  //useEffect(() => {
    //AntiDetectionInterval.current = (setInterval(() => print(loopQ, obfuscated, deobfuscated, template, AntiDetectionInterval), 25))
    //AntiDetectionTitleInterval.current = (setInterval(() => print(loopQTitle, obfuscatedTitle, deobfuscatedTitle, templateTitle, AntiDetectionTitleInterval), 25))
  //}, [])


  useEffect(() => {
    // console.log(totalVars)
    for (let i = 0; i < totalRefs.current.length; i++) {
      
      AppendObserver(totalRefs.current[i], i)
    }
  }, [])
  
  const generateIntervals = (intervalVar, loopVar, obfuscatedVar, deobfuscatedVar, templateVar) => {
    // console.log("interval being generated")
    intervalVar.current = (setInterval(() => print(loopVar, obfuscatedVar, deobfuscatedVar, templateVar, intervalVar), 25))
  }

  const totalRefs = useRef([ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8])
  const totalVars = useRef([])

  function GenerateRequirements(textV) {

          const tempLoop = useRef(0)
          const tempObf = useRef(textV)
          const tempTemplate = useRef(textV)
          const tempDeObf = useRef("")
          const tempInterval = useRef(null)
          const tempVisRef = useRef()

          let varList = [tempLoop, tempObf, tempTemplate, tempDeObf, tempInterval, tempVisRef]
      
      if (totalVars.current.length > 0) {
        if (totalVars.current[totalVars.current.length-1][1].current != textV) {
          
          
          totalVars.current.push(varList)

          return([varList]);

        }
        
      } else {
        
        totalVars.current.push(varList)

        return([varList]);
      
      }
       
      return("didnt pass if statements")
  
  }

  function AppendObserver(toRef, index) {
    // console.log("making observer")
    const intersectVar = new IntersectionObserver((entries) => {
      
      if (entries[0].isIntersecting && totalVars.current[index][0].current == 0) {
        // console.log("attemping to generate interval")
        generateIntervals(totalVars.current[index][4], totalVars.current[index][0], totalVars.current[index][1], totalVars.current[index][3], totalVars.current[index][2])
      }
    })

    intersectVar.observe(toRef.current)
    return("")
  }

  const printtotalrefs = () => {
    // console.log(totalVars)
  }

  const FirstTitleVars = GenerateRequirements("More-human writing")
  const FirstDescVars = GenerateRequirements("Create AI-generated texts that sound less robotic and can even fool AI writing detection systems.")

  const SecondTitleVars = GenerateRequirements("Decisive Writing")
  const SecondDescVars = GenerateRequirements("Eliminate writer's block with suggestions from the AI. Or, let it continue for you!")

  const ThirdTitleVars = GenerateRequirements("Fast Writing")
  const ThirdDescVars = GenerateRequirements("Save hours or even days of precious time writing business emails, blog posts, or your next big hit.")

  const FourthTitleVars = GenerateRequirements("Intricate Rewriting")
  const FourthDescVars = GenerateRequirements("Stuck on editing or rewriting the same text? Prompt the AI to rephrase your work.")

  const items = [
    {
      title: <div><b style={{fontWeight: "normal"}}>{totalVars.current[0][3].current}</b><b style={{color: corruptionColor}}>{totalVars.current[0][1].current}</b></div>,
      description:
        <div><b style={{fontWeight: "normal"}}>{totalVars.current[1][3].current}</b><b style={{color: corruptionColor}}>{totalVars.current[1][1].current}</b></div>,
      image: sneakSvg,
      titleVars: FirstTitleVars,
      descVars: FirstDescVars,
      titleRef: ref1,
      descRef: ref2,

    },
    {
      title: <div><b style={{fontWeight: "normal"}}>{totalVars.current[2][3].current}</b><b style={{color: corruptionColor}}>{totalVars.current[2][1].current}</b></div>,
      description:
      <div><b style={{fontWeight: "normal"}}>{totalVars.current[3][3].current}</b><b style={{color: corruptionColor}}>{totalVars.current[3][1].current}</b></div>,
      image: lightbulbSvg,
      titleVars: SecondTitleVars,
      descVars: SecondDescVars,
      titleRef: ref3,
      descRef: ref4

    },
    {
      title: <div><b style={{fontWeight: "normal"}}>{totalVars.current[4][3].current}</b><b style={{color: corruptionColor}}>{totalVars.current[4][1].current}</b></div>,
      description:
        <div><b style={{fontWeight: "normal"}}>{totalVars.current[5][3].current}</b><b style={{color: corruptionColor}}>{totalVars.current[5][1].current}</b></div>,
      image: relaxSvg,
      titleVars: ThirdTitleVars,
      descVars: ThirdDescVars,
      titleRef: ref5,
      descRef: ref6,
    },
    {
      title: <div><b style={{fontWeight: "normal"}}>{totalVars.current[6][3].current}</b><b style={{color: corruptionColor}}>{totalVars.current[6][1].current}</b></div>,
      description:
      <div><b style={{fontWeight: "normal"}}>{totalVars.current[7][3].current}</b><b style={{color: corruptionColor}}>{totalVars.current[7][1].current}</b></div>,
      image: agreementSvg,
      titleVars: FourthTitleVars,
      descVars: FourthDescVars,
      titleRef: ref7,
      descRef: ref8
    },
  ];

  return (

      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >

        <Container>
                <SectionHeader
                  title={props.title}
                  subtitle={props.subtitle}
                  size={4}
                  textAlign="center"
                />
                
                <Container
                  maxWidth="md"
                  disableGutters={true}
                  className={classes.itemsContainer}
                >
                  

                  {items.map((item, index) => (
                    <Grid
                      className={classes.row}
                      container={true}
                      item={true}
                      alignItems="center"
                      spacing={4}
                      key={index}
                    >
                      <Grid item={true} xs={12} md={6}>
                        <Box
                          textAlign={{
                            xs: "center",
                            md: "left",
                          }}
                        >
                          
                          <Typography ref={item.titleRef} style={{fontFamily: "fontification"}} variant="h5" gutterBottom={true}>
                            {item.title}
                          </Typography>

                          <Typography ref={item.descRef} style={{fontFamily: "fontification"}} variant="subtitle1">
                            {item.description}
                          </Typography>
          
                        </Box>
                      </Grid>
                      <Grid item={true} xs={12} md={6}>
                        <figure className={classes.figure}>
                          <img
                            src={item.image}
                            alt={item.title}
                            className={classes.image}
                          />
                        </figure>
                      </Grid>
                    </Grid>
                  ))}
                </Container>
            </Container>

        </Section>
  );
}

export default FeaturesSection;
