
import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import InputBase from '@material-ui/core/InputBase';
import StarIcon from "@material-ui/icons/Star";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import EditItemModal from "./EditItemModal";
import { useAuth } from "./../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "./../util/db";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from "@material-ui/core/Grid";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SendIcon from "@material-ui/icons/Send"
import './general.css'
import HomeIcon from "@material-ui/icons/Home"
import { collection, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { firebaseApp } from "../util/firebase";
import { doc } from "firebase/firestore"
import Select from "@material-ui/core/Select"
import { ClickAwayListener, Icon, MenuItem } from "@material-ui/core";
import { Home, Translate } from "@material-ui/icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { redirectToCheckout } from "../util/stripe";
import CheckoutForm from "./CheckoutForm";
import { Popper } from "@material-ui/core";
import { Link } from "react-router-dom"
import { useDarkMode } from "../util/theme";
import { useRef } from "react";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import { getAuth } from "firebase/auth";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { Info } from "@material-ui/icons";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import "./monospacefontcss.css"
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  understandButton: {
    className: "understandButtonClass"
  }
}));


function DashboardItems(props) {
  const classes = useStyles();

  const auth = useAuth();
  const db = getFirestore(firebaseApp)
  const darkMode = useDarkMode()

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(auth.user.uid);
  
  const [underlined, getUnderlined] = useState("");
  
  const [creatingItem, setCreatingItem] = useState(false);

  const [updatingItemId, setUpdatingItemId] = useState(null);

  const itemsAreEmpty = !items || items.length === 0;

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const handleStarItem = (item) => {
    if (canUseStar) {
      updateItem(item.id, { featured: !item.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };
  const [textValue, setTextValue] = useState("");
  const [checks, setChecks] = useState({field1: 0, field2: 0, field3: 0, field4: 0});
  const [currentIcon, setCurrentIcon] = useState(<SendIcon style={{width: 15, height: 15}}/>)
  const [uiInteractable, setUIInteractable] = useState(true)
  //test2
  const handleChange = (e) => {
    setTextValue(e.target.value);
  };



  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  async function api_send() {
    let archivedTextValue = textValue
/*  if (!canUseStar){
    alert("You have no more words on this plan! Upgrade or buy words to use this feature")
    return;
  } */

  let max_tokens_length = 50;
  
  setCurrentIcon(<CircularProgress style={{width: 15, height: 15}}/>)
  setUIInteractable(false)
  if (getWords() != 0) {

    try {
      getAuth(firebaseApp).currentUser.getIdToken(true).then((jwt) => {
                                                                                          //  /test/prompt
        const es = axios.post("https://njokw4gg32hjmu2dgziab335su0kvofz.lambda-url.us-east-1.on.aws/", {
          "jwt": (jwt),
          "prompt": textValue,
          "tunetext": String(tuneText.split(RegExp("([^\u0000-\u0040\u005B-\u0060\u007B-\u00BF\u02B0-\u036F\u00D7\u00F7\u2000-\u2BFF])+")).length-1)/2 > 500 ? tuneText : ""
          ,

        }, {
            "Origin": "https://www.incog.ai"
          }).catch(async () => {

          for (var uniqueVariableName = 0; uniqueVariableName < 10; uniqueVariableName++) {
            await timeout(5000).then(() => {
              getOuts().then((outList) => {
                if (responseList != outList) {
                  setSelectable("")
                  if (responseList[0] == "And a response will appear that looks something like this!" &&  promptList[0] == "example prompt")
                  {
                    
                    responseList.pop()
                    setResponseList([...responseList])
                
                    promptList.pop()
                    setPromptList([...promptList])
                    setOpac(1)
                
                  }

                  setResponseList(outList)
                  uniqueVariableName = 10
                }
              })
            }) 
              
            
          }

          setCurrentIcon(<SendIcon style={{width: 15, height: 15}}/>)
          setUIInteractable(true)
          
        });

        const resultant = es.then(async (res) => {
          try {
            setCurrentIcon(<SendIcon style={{width: 15, height: 15}}/>)
            setUIInteractable(true)
    
            let final_text = ""
          
            final_text = res["data"]
            setSelectable("")
            if (responseList[0] == "And a response will appear that looks something like this!" &&  promptList[0] == "example prompt")
            {
              
              responseList.pop()
              setResponseList([...responseList])
          
              promptList.pop()
              setPromptList([...promptList])
              setOpac(1)
              setSelectable("")
          
            }
            await timeout(100).then(() => {
              getWords().then((res) => {
                forceUpdate(new Date())
                setCurrentWords(res)
              })
            })
            
          
            responseList.push(final_text)
            setResponseList([...responseList]);
          
            promptList.push(archivedTextValue)
            setPromptList([...promptList])
            
              //const r_json = await(es.json())
              //console.log(r_json)
              //console.log(r_json.choices[0])
          } catch (e) {
            // Errors may be expected here
          }

          })
        
      })
        
      

    }
    catch (err) {
      console.log(err)
      setCurrentIcon(<SendIcon style={{width: 15, height: 15}}/>)
      setUIInteractable(true)
      
    }
  

  }

  };


  async function getWords() {
    
    let docData = await getDoc(doc(db, "users", auth.user.id))
    if (docData.exists())
    {
      return(docData.data()["words"])
    } else {return("Account not found.")}

  }

  async function getOuts() {
    
    let docData = await getDoc(doc(db, "users", auth.user.id))
    if (docData.exists() )
    {
      return(docData.data()["outputs"])
    } else {return("None found.")}

  }

  async function updateWords(value){

    await updateDoc(doc(db, "users", auth.user.id), {
      words: value,
    });
  }

  
  const [responseList, setResponseList] = useState(["And a response will appear that looks something like this!"])
  const [promptList, setPromptList] = useState(["example prompt"])
  const [opac, setOpac] = useState(0.5)
  const [selectable, setSelectable] = useState("none")
  const [currentWords, setCurrentWords] = useState(0)
  const [isCheckingOut, setIsCheckingOut] = useState(false)
  const [tuneText, setTuneText] = useState("")

  useEffect(() => {
    
    
    getWords().then((res) => {
      if (res != undefined)
      {
        setCurrentWords(res)
        getOuts().then((outs) => {
          if (outs != "None found." && outs != null) {
            setResponseList(outs)
            let temparr = []
            outs.map((item, index) => {
              temparr.push("Old Prompt")
            })
            setPromptList([...temparr])
            setOpac(1)
          }
        })
        if (res == 0) {
          getTimeUntilRenew()
        }
      } else {
        setDisclaimerOpen(true)
        setCurrentWords(25000)
      }
    })
  }, [])
  




  const [anchorEl, setAnchorEl] = useState(null)
  const [disclaimerOpen, setDisclaimerOpen] = useState(false)

  const open = Boolean(anchorEl)
  const id = open ? "simple-popper" : undefined


  const [anchorElInfo, setAnchorElInfo] = useState(null)
  const infoOpened = Boolean(anchorElInfo)
  const infoId = infoOpened ? "simple-popper2" : undefined

  const handlePopper = (ev) => {
    setAnchorElInfo(anchorElInfo ? null : ev.currentTarget)
  }

  const timeHeldInterval = useRef(null)
  const timeHeldFor = useRef(0)
  const [, forceUpdate] = useState(0)
  const [animNameVar, setAnimNameVar] = useState("white")
  const [untilRenew, setUntilRenew] = useState("?")
  const [dropDownShowing, setDropDownShowing] = useState(false)
  const [dropdownClass, setDropdownClass] = useState("")

  const held = () => {
    if (timeHeldFor.current < 3) {
      timeHeldFor.current = timeHeldFor.current + 1
      forceUpdate(timeHeldFor.current)
    } 
    if (timeHeldFor.current == 3) {
      clearInterval(timeHeldInterval.current)
      setDisclaimerOpen(false)
    }
  }

  const released = () => {
    timeHeldFor.current = 0
    forceUpdate("released or aborted")
    clearInterval(timeHeldInterval.current)
    timeHeldInterval.current = null
  }

  const getTimeUntilRenew = () => {
    getAuth(firebaseApp).currentUser.getIdToken(true).then((jwt) => {
      const renewText = fetch ("https://njokw4gg32hjmu2dgziab335su0kvofz.lambda-url.us-east-1.on.aws/", {
        "method": "POST",
        "mode": "cors",
        "body": JSON.stringify({
          "jwt": (jwt),
          "prompt": "",
          "tunetext": ""
        }),
      })

      const date = renewText.then((res) => {
        res.text().then((text) => {
          if (parseInt(text)>=30) {
            setCurrentWords(25000)
          }
          setUntilRenew(text)
        })
      })
    })
  }

  return (
    <>
      {auth.user.planIsActive ? (
        <>
          
          {disclaimerOpen ? (
            <div>
              <Box style={{width: "200vw", marginLeft: 0, padding: 0, height: "200vh", zIndex: 1000000000, backgroundColor: "black", opacity: 0.5, position: "fixed", transform: "translate(-50%, -50%)"}}></Box>
              <Box style={{position: "absolute", zIndex: 1000000000000, backgroundColor: darkMode.value ? "#3e403f" : "white", width: "45%", minWidth: 250, borderRadius: 12, marginLeft: "45%", transform: "translate(-50%, 0px)", textAlign: "center", padding: 10}}>
                <h1>Disclaimer:</h1>
                <sub>Our product is in beta and in no way assures that the content will always be undetectable, copyright-free, or plagiarism free. We do not encourage the use of this product for dishonest practices, and assume no responsibility for any damages incurred by the use of this product. Use this product at your own risk.</sub>
                <br/>
                <br/>
                <div>
                <a href={"/"} style={{textDecoration: "none"}}><Button endIcon={<CancelIcon style={{color: "red"}}/>} variant="contained" disableElevation={true} disableFocusRipple={true} disableTouchRipple={true} style={{width: "100%", marginBottom: 10, backgroundColor: darkMode.value ? "#2f3030" : "#c6c6c6", color: darkMode.value ? "white" : "black"}}>TAKE ME BACK</Button></a>
                <Button endIcon={<CheckIcon style={{color: "green"}}/>} onClick={() => {setDisclaimerOpen(false)}} disableElevation={true}  variant="contained" disableFocusRipple={true} disableTouchRipple={true} style={{width: "100%", color: darkMode.value ? "white" : "black", background: darkMode.value ? "rgba(47,48,48,1)" : "rgba(198,198,198,1)", backgroundSize: "500%, 0px", backgroundPosition: "26.5% 0px", animation: animNameVar}}>I UNDERSTAND</Button>
                </div>
              </Box>
            </div>
          ) : (
            <h1></h1>
          )}

          <form>
            
          </form>
          <a href="./"><IconButton style={{height: 48, width: 48}}><HomeIcon></HomeIcon></IconButton></a>
		  <IconButton
                color="inherit"
                onClick={darkMode.toggle}
                style={{ opacity: 0.6, float: "right"}}
              >
                {darkMode.value && <NightsStayIcon />}

                {!darkMode.value && <WbSunnyIcon />}
          </IconButton>
          <h1 style={{textAlign: "center", fontSize: 45, fontFamily: "fontification"}}>incog.<b style={{color: "#8585ad", fontSize: 45}}>ai</b></h1>
          <h1 style={{fontFamily: "fontification", marginLeft: "50%", transform: "translate(-50%, 0px)", textAlign: "center", marginBottom: "50px"}}>Keep in mind that our product is still in <b className={"rainbowTextClass"}>beta</b>, possibly resulting in long load times or other unwanted features.</h1>
          

          <div style={{opacity: 0, position: "absolute"}}>
            <Box style={{width: "70%", height: 100, maxWidth: 350, marginLeft: "50%", transform: "translate(-50%, 0px)"}}>
              <Grid style={{position: "absolute", marginTop: 34}} container>
                <Grid item xs={10} md={10} xl={10}>
                  <InputBase disabled={true} placeholder="Enter your Open-AI api key." style={{backgroundColor: darkMode.value ? "#3b3b38" : "#E5E5E5", borderTopLeftRadius: 12, borderBottomLeftRadius: 12, paddingLeft: 16, width: "100%"}}></InputBase>
                </Grid>
                <Grid item xs={2} md={2} xl={2}>
                  <Button  disabled={true} variant="contained" disableElevation={true} style={{height: 32, backgroundColor: darkMode.value ? "#262624" : "#a6a6a6", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 12, borderBottomRightRadius: 12, width: "100%", minWidth: 0, color: "white"}}>Save</Button>
                </Grid>
              </Grid>
            </Box>
          </div>

          
          <Box style={{width: "80%", height: 50, fontFamily: "fontification", marginLeft: "50%", transform: "translate(-50%, 0px)", backgroundColor: darkMode.value ? "#363431" : "#F4F4F4", borderRadius: 12, boxShadow: "0px 0px 18px black"}}>
          <IconButton onClick={() => {setDropdownClass(!dropDownShowing ? "dropDownOpen" : "dropDownClose"); setDropDownShowing(!dropDownShowing)}}>{dropDownShowing ? <ArrowDropUp/> : <ArrowDropDown/>}</IconButton>
          Feature Identifier
          <IconButton onClick={handlePopper} style={{justifyContent: "right", alignItems: "right", float: "right"}}><Info></Info></IconButton>
            <Popper id={infoId} open={infoOpened} anchorEl={anchorElInfo} placement="top-start">
              <ClickAwayListener onClickAway={() => {setAnchorElInfo(null)}}>
                <>
                  <Box style={{backgroundColor: darkMode.value ? "#403835" : "#F0F0F0", width: "max(20vw, 200px)", borderRadius: 12, padding: 15, boxShadow: "0px 0px 8px black"}}><desc style={{fontFamily: "fontification"}}>Text insterted into this area will be considered when prompting the AI. The AI will attempt to imitate certain features of the input, which can include tense, level of creativity, or even structure and content.</desc></Box>
                </>
              </ClickAwayListener>
            </Popper>

        </Box>
        
          
        {dropDownShowing && (
          <Box className={dropdownClass} style={{width: "77%", padding: 8, fontFamily: "fontification", height: 250, transition: 3, marginLeft: "50%", textAlign: "center", transform: "translate(-50%, 0px)", overflowY: "scroll", backgroundColor: darkMode.value ? "#363431" : "#F4F4F4", position: dropDownShowing ? "relative": "absolute", opacity: 0, marginTop: 10, borderRadius: 12, boxShadow: "0px 5px 18px black"}}>
              <InputBase inputProps={{style: {fontFamily: "fontification"}}} endIcon={<Info></Info>} onChange={(ev) => {setTuneText(ev.target.value)}} style={{width: "100%", paddingRight: 45, backgroundColor: darkMode.value ? "#494745" : "#D4D4D4", borderRadius: 12, padding: 15}} multiline={true} placeholder="Input 500-1000 words. Note: Be sure to keep it under 1000.">
              </InputBase>
              <sub style={{textAlign: "center", textShadow: darkMode.value ? "0px 1px 2px black" : ""}}>{String(tuneText.split(RegExp("([^\u0000-\u0040\u005B-\u0060\u007B-\u00BF\u02B0-\u036F\u00D7\u00F7\u2000-\u2BFF])+")).length-1)/2 >= 500 ? <sub style={{color: "green", fontSize: "max(16px, 1vw)"}}>{"Sufficient word count (" + String(tuneText.split(RegExp("([^\u0000-\u0040\u005B-\u0060\u007B-\u00BF\u02B0-\u036F\u00D7\u00F7\u2000-\u2BFF])+")).length-1)/2 + "/500), your input will be used."}</sub> : <sub style={{color: "red", fontSize: "max(16px, 1vw)"}}>{"Insufficient or too great of a word count (" + String(tuneText.split(RegExp("([^\u0000-\u0040\u005B-\u0060\u007B-\u00BF\u02B0-\u036F\u00D7\u00F7\u2000-\u2BFF])+")).length-1)/2 + "/500), your input will not be used."}</sub>}</sub>
          </Box>
        )}
          {currentWords <= 0 && (
            <Box style={{width: "35%", borderRadius: 12, backgroundColor: darkMode.value ? "#3b3b38" : "#E5E5E5", marginLeft: "50%", transform: "translate(-50%, 0px)", padding: 8, color: "red", opacity: (currentWords<=0) ? 1 : 0, position: (currentWords<=0) ?  "relative" : "absolute", marginTop: 35}}>You have run out of words, your next refill is in {Math.max(0, 30-parseInt(untilRenew))} days.</Box>
          )}
          
          <Box style={{width: "90%", height: 400, backgroundColor: darkMode.value ? "#363431" : "#F4F4F4", borderRadius: 12, display: "flex", flexDirection: "column", marginLeft: "5%", marginTop: 50, overflowY: "scroll", paddingBottom: 15, boxShadow: "0px 0px 18px black"}}>

          <Grid container style={{fontFamily: "fontification"}}>
            <Grid item xs={8} md={8} xl={8}>
              <InputBase inputProps={{style: {fontFamily: "fontification"}}} disabled={!uiInteractable || (currentWords<=0)} placeholder="Enter a prompt here." endAdornment={<IconButton disabled={!uiInteractable || (currentWords<=0)} onClick={api_send}>{currentIcon}</IconButton>} onChange={handleChange} style={{width: "96.7%", marginTop: 15, minWidth: 150, marginLeft: "3.5%", backgroundColor: darkMode.value ? "#3b3b38" : "#E5E5E5", borderRadius: 12, paddingLeft: 15, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0}}></InputBase>
            </Grid>
            <Grid item xs={3} md={3} xl={3}>
              <Box style={{width: "90%", height: "39px", backgroundColor: darkMode.value ? "#3b3b38" : "#E5E5E5", marginTop: 15}}>
                <Box style={{transform: "translateY(15px)", fontFamily: "Tile-Neon"}}>
                  <h1 style={{fontSize: "min(18px, 2vw)", fontWeight: "normal", color: (currentWords<=0) ? "red" : "#888888", transform: "translateY(max(-10px, -3vw))", textAlign: "center", padding: 2, lineHeight: "10px", paddingBottom: "4px"}}><sub style={{fontFamily: "fontification", fontWeight: "bold"}}>Words Remaining: {currentWords}</sub></h1>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1} md={1} xl={1}>
              <Link style={{textDecoration: "none"}} to={"/purchasewords"}><Button variant="contained" disableElevation={true} style={{marginTop: 15, height: 39, borderBottomLeftRadius: 0, borderTopLeftRadius: 0, minWidth: 0, marginLeft: "-30%", borderTopRightRadius: 15, borderBottomRightRadius: 15, backgroundColor: darkMode.value ? "#262624" : "#a6a6a6", color: "white", fontSize: 8, width: "100%", textDecoration: "none", fontSize: "min(9px, 1.2vw)"}} disabled={true}>COMING SOON</Button></Link>
            </Grid>
          </Grid>

          <Popper id={id} open={open} anchorEl={anchorEl}>
            <Box style={{width: "100px", height: "100px", backgroundColor: "red"}}></Box>
          </Popper>
            
            {
            responseList.map((item, index) => (
              <Box key={index} style={{fontFamily: "fontification", opacity: promptList[index] == "Old Prompt" ? 0.5 : opac, width: "95%", marginLeft: "2.5%", marginTop: "10px", backgroundColor: darkMode.value ? "#262624" : "#CCCCCC", borderRadius: 12, paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px", boxShadow: darkMode.value ? "0px 0px 8px gray" : "0px 0px 8px black"}}>
                <h1 style={{fontWeight: "lighter", fontSize: 16, userSelect: selectable, whiteSpace: "pre-wrap"}}>{item}</h1>
                <small style={{padding: 0, color: "gray" ,userSelect: selectable}}>{"prompt: " + promptList[index]}</small>
              </Box>
            ))
          }
          </Box>
        </>
      ): (<h1 style={{textAlign: "center"}}>To access the dashboard, you must purchase any of the plans available. If you have purchased but are still seeing this text, we are processing your payment still, please wait...</h1>)}
    
      
    </>
  );
}

export default DashboardItems;

