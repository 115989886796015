import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useState } from "react";
import "./monospacefontcss.css"
import "./general.css"
import { useDarkMode } from "../util/theme";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    border: useDarkMode().value ? "1px solid white" :"1px solid gray",
    boxShadow: useDarkMode().value ? "0px 0px 10px white" : "0px 0px 10px gray",
    backgroundColor: useDarkMode().value ? "#424242" :"#F0F0F0"
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(3),
  },
  price: {
    display: "flex",
    alignItems: "baseline",
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  perkIcon: {
    minWidth: 34,
    color: theme.palette.success.main,
  },
}));

function PricingSection(props) {
  const classes = useStyles();
  const isDark = useDarkMode().value

  const auth = useAuth();
  const [forceUpdate, setForceUpdate] = useState()
  const charArr = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")"];

  const generateRandomTextOfLength = (len, ref) => {
    let tempStr = ""
    for (let i = 0; i < len; i++) {
      tempStr = tempStr + charArr[Math.floor(Math.random() * charArr.length)]
    }
    ref.current = tempStr
    setForceUpdate(tempStr)
  }
  const pricePro = React.useRef("?")
  const priceBusiness = React.useRef("?")
  const pro1 = React.useRef("COMING SOON")
  const pro2 = React.useRef("COMING SOON")
  const pro3 = React.useRef("COMING SOON")
  const pro4 = React.useRef("COMING SOON")
  const pro5 = React.useRef("COMING SOON")
  const pro6 = React.useRef("COMING SOON")

  //useEffect(() => {
  //  setInterval(() => {generateRandomTextOfLength(2, pricePro)}, 50)
  //  setInterval(() => {generateRandomTextOfLength(2, priceBusiness)}, 50)
  //  setInterval(() => {generateRandomTextOfLength(12, pro1)}, 50)
  //  setInterval(() => {generateRandomTextOfLength(9, pro2)}, 50)
  //  setInterval(() => {generateRandomTextOfLength(19, pro3)}, 50)
  //
  //}, [])

  const plans = [
    {
      id: "starter",
      name: "Basic",
      price: "9.99",
      perks: [
        "Access to our advanced AI writing systems",
		"25,000 words per month",
		"Up to 3000 words per response"
      ],
    },
    {
      id: "pro",
      name: "COMING SOON",
      price: pricePro.current,
      perks: [
        pro1.current,
        pro2.current,
        pro3.current,
        pro2.current,
        pro1.current,
		    pro2.current,
      ],
    },
    {
      id: "business",
      name: "Professional",
      price: "Custom",
      perks: [
        "Access Special AI-writers tuned by our experts",
		"Have the AI accurately imitate your individual writing style",
		"Create custom AI models that fit your use case",
		"Customizable words per month."
      ],
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <h1 style={{fontFamily: "fontification", textAlign: "center", marginBottom: "5px"}}>All plans include <b style={{color: "#59A7FD", fontFamily: "fontification"}}>14-day free trial</b>.</h1>
        <h1 style={{fontFamily: "fontification", textAlign: "center", marginBottom: "35px"}}>Cancel <b style={{color: "#59A7FD", fontFamily: "fontification"}}>any time</b>.</h1>
        <Grid container={true} justifyContent="center" spacing={4}>
          {plans.map((plan, index) => (
            <Grid item={true} xs={12} md={4} key={index} className={"fadeInClass"} style={{animationDelay: parseFloat((index)*0.35) + "s"}}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography style={{fontFamily: "fontification"}} variant="h6" component="h2">
                    {plan.name}
                  </Typography>
                  <Box className={classes.price} mt={1}>
					{plan.price != "Custom" ? <Typography style={{fontFamily: "fontification"}} variant="h3">${plan.price}</Typography> : <Typography style={{fontFamily: "fontification"}} variant="h3">{plan.price}</Typography>}
                    <Typography style={{fontFamily: "fontification"}} variant="h4" color="textSecondary">
                      /mo
                    </Typography>
                  </Box>

                  {plan.description && (
                    <Box mt={2}>
                      <Typography style={{fontFamily: "fontification"}} component="p" color="textSecondary">
                        {plan.description}
                      </Typography>
                    </Box>
                  )}

                  {plan.perks && (
                    <Box mt={1}>
                      <List aria-label="perks">
                        {plan.perks.map((perk, index) => (
                          <ListItem
                            className={classes.listItem}
                            disableGutters={true}
                            key={index}
                            
                          >
                            <ListItemIcon className={classes.perkIcon}>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText style={{fontFamily: "fontification"}}><div style={{fontFamily: "fontification"}}>{perk}</div></ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}

                  <Box mt="auto" pt={3}>
                    <Button
                      component={Link}
                      to={
						  plan.price != "Custom" ?
								auth.user
								  ? `/purchase/${plan.id}`
								  : `/auth/signup?next=/purchase/${plan.id}` :
								auth.user
								  ? `/contact-sales`
								  : `/auth/signup?next=/contact-sales`
								  
								  
                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth={true}
                      disabled={plan.price != "?" ? false : true}
                    >
					 {plan.price != "Custom" ? <div>Choose</div> : <div>Contact Sales</div>}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default PricingSection;
