import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { apiRequestExternal } from "./../util/util.js";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { doc, getFirestore, getDoc } from "firebase/firestore"
import { firebaseApp } from "../util/firebase";
import "./general.css"
import HomeIcon from "@material-ui/icons/Home"
import IconButton from "@material-ui/core/IconButton"
import "./monospacefontcss.css"

function Contact(props) {
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { handleSubmit, register, errors, reset } = useForm();
  const [userEmail, setUserEmail] = useState("")
  const auth = useAuth()
  const db = getFirestore(firebaseApp)


  async function getEmail() {
    
    let docData = await getDoc(doc(db, "users", auth.user.id))
    if (docData.exists())
    {
      return(docData.data()["email"])
    } else {return("Account not found.")}

  }
	
  useEffect(() => {
    
    
    getEmail().then((res) => {
      if (res != undefined)
      {
        setUserEmail(res)

      } 
    })
  }, [])
  const endpoint = `https://formspree.io/${process.env.REACT_APP_PREMIUM_FORMSPREE_ID}`;

  function submitFunc(data) {
	return apiRequestExternal(endpoint, "POST", data);
  }

  const contact = { submitFunc };

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    submitFunc(data)
      .then(() => {
        // Clear form
        reset();
        // Show success alert message
        setFormAlert({
          type: "success",
          message: "Your message has been sent!",
        });
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
   <>
       <a href="./" style={{padding: 15}}><IconButton style={{height: 48, width: 48}}><HomeIcon></HomeIcon></IconButton></a>
       <h1 style={{textAlign: "center", fontFamily: "fontification"}}>Sales Contact Form</h1>
	   <Section
		  bgColor={props.bgColor}
		  size={props.size}
		  bgImage={props.bgImage}
		  bgImageOpacity={props.bgImageOpacity}
		>
		  <Container maxWidth="md">
			<SectionHeader
			  title={props.title}
			  subtitle={props.subtitle}
			  size={4}
			  textAlign="center"
			/>
					{formAlert && (
				<Box mb={3}>
				  <Alert severity={formAlert.type}>{formAlert.message}</Alert>
				</Box>
			  )}

			  <form onSubmit={handleSubmit(onSubmit)}>
				<Grid container={true} spacing={2}>
				  {props.showNameField && (
					<Grid item={true} xs={12} md={6}>
					  <TextField
						variant="outlined"
						type="text"
						label="Name"
						name="name"
						error={errors.name ? true : false}
						helperText={errors.name && errors.name.message}
						fullWidth={true}
						inputRef={register({
						  required: "Please enter your name",
						})}
					  />
					</Grid>
				  )}

				  <Grid item={true} xs={12} md={props.showNameField ? 6 : 12}>
					<TextField
					  variant="outlined"
					  type="email"
					  label="Email"
					  name="email"
					  error={errors.email ? true : false}
					  helperText={errors.email && errors.email.message}
					  fullWidth={true}
					  inputRef={register({
						required: "Please enter your email",
					  })}
					/>
				  </Grid>
				  <Grid item={true} xs={12}>
					<TextField
					  variant="outlined"
					  type="text"
					  label="Enter your inquiry."
					  name="message"
					  multiline={true}
					  rows={5}
					  error={errors.message ? true : false}
					  helperText={errors.message && errors.message.message}
					  fullWidth={true}
					  inputRef={register({
						required: "Please enter a message",
					  })}
					/>
				  </Grid>
				  <Grid item={true} xs={12}>
					<Button
					  variant="contained"
					  color="primary"
					  size="large"
					  type="submit"
					  disabled={pending}
					>
					  {!pending && <span>Submit</span>}
					
					  {pending && <CircularProgress size={28} />}
					</Button>
				  </Grid>
				</Grid>
			  </form>
		  </Container>
		</Section>
    </>
  );
}

export default Contact;
