import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./monospacefontcss.css"

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "What do you mean by humanized?",
      answer: "Our AI writer is less likely to exhibit common characteristics of AI writing. We can't list all of these characteristics due to the proprietary nature of our techniques. However, this leads to many outputs sounding more human, and often fooling AI detection systems - which use common characteristics of AI to distiguish between human and AI written text."
    },
    {
      question: "What's with the \"COMING SOON\" plans?",
      answer:
        "Currently, we only provide the starter plan, because we believe that everyone should have access to our app in its current (beta) state. As we release more updates, users will have the option to upgrade to get access to exclusive and exciting new premium features."
    },
    {
      question: "What kind of updates can I expect in the future?",
      answer:
        "We currently can't reveal too much about our next moves, however, we have many big things planned, including a roadmap.",
    },
    {
      question: "What is the 'Feature Identifier' area?",
      answer: "This is an experimental feature that allows users to write in a way that emulates certain features from the input text. Text entered into this area will be considered when prompting the AI below. The AI will try to keep things such as: the tense, level of creativity, and sometimes the structure and content of the text input into this area. Since this is experimental, this doesn't always work and users are encouraged to handle outputs (and inputs) with this in mind."
    },
    {
      question: "What kind of writing can the AI do?",
      answer: "Anything! Although, our model can struggle with certain types of outputs in this early stage (beta), such as numbered lists."
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6" style={{fontFamily: "fontification"}}>{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography style={{fontFamily: "fontification"}}>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
