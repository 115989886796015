import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";
import Navbar from "../components/Navbar";

function ContactPage(props) {
  return (
    <>

      <Navbar
          color="default"
          logo="https://uploads.divjoy.com/logo.svg"
          logoInverted="https://uploads.divjoy.com/logo-white.svg"
      />

      <Meta title="Contact" />
      <ContactSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Give your feedback."
        subtitle=""
        buttonText="Send feedback"
        buttonColor="primary"
        showNameField={true}
      />
    </>
  );
}

export default ContactPage;
