import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";
import Navbar from "../components/Navbar";

function AuthPage(props) {
  const router = useRouter();

  return (
    <>

      <Navbar
          color="default"
          logo="https://uploads.divjoy.com/logo.svg"
          logoInverted="https://uploads.divjoy.com/logo-white.svg"
      />

      <Meta title="Auth" />
      <AuthSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        providers={["google"]}
        afterAuthPath={router.query.next || "/"}
      />
    </>
  );
}

export default AuthPage;
