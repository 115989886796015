import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import "./general.css"
import { useAuth } from "../util/auth";

function HeroSection(props) {
  const auth = useAuth()

  return (
    <div>
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <Box textAlign="center">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={3}
            />
            
            {!auth.user?.planIsActive && (
                <Button
                component={Link}
                to={props.buttonPath}
                variant="contained"
                size="large"
                color={props.buttonColor}
              >
                {props.buttonText}
              </Button>
            )}
            {auth.user?.planIsActive && (
                <Button
                component={Link}
                to={"/dashboard"}
                variant="contained"
                size="large"
                color={props.buttonColor}
              >
                GO TO DASHBOARD
              </Button>
            )}
          </Box>
        </Container>
      </Section>
    </div>
  );
}

export default HeroSection;
